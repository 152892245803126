import React, { useMemo, useCallback } from "react";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import Url from "url-parse";

import { useIntl } from "react-intl";
import ImageCreator from "./assets/creator_badge.svg";

import styles from "./Avatar.module.scss";

type AvatarSize =
  | "small"
  | "medium"
  | "large"
  | "custom_40"
  | "custom_70"
  | "custom_100";
export interface Props {
  uid: string;
  url: string;
  displayName?: string;
  creator?: boolean;
  size?: AvatarSize;
  onClick?: (uid: string) => void;
  className?: string;
}

const WIDTHS = {
  small: 32,
  medium: 64,
  large: 128,
  custom_40: 40,
  custom_70: 70,
  custom_100: 100,
};

const Avatar = ({
  uid,
  url,
  displayName,
  creator,
  size = "large",
  onClick,
  className,
}: Props): JSX.Element => {
  const intl = useIntl();
  const width = WIDTHS[size];

  const handleOnClick = useCallback(() => {
    onClick && onClick(uid);
  }, [onClick]);

  const avatarUrl = useMemo(
    () =>
      url
        .replace("{width}", width.toString())
        .replace("{height}", width.toString())
        .replace("{scale_factor}", "2"),
    [url, width]
  );

  const placeholderUrl = useMemo(
    () =>
      url
        .replace("{width}", "2")
        .replace("{height}", "2")
        .replace("{scale_factor}", "2"),
    [url]
  );

  const ext = useMemo(
    () => Url(avatarUrl).pathname.replace(/.*\./, ""),
    [avatarUrl]
  );

  const avatarUrlWebp = useMemo(
    () => avatarUrl.replace(/\.(png|jpg)/, ".webp"),
    [avatarUrl]
  );

  return (
    <div
      className={classNames(styles.wrapper, styles[size], className, {
        [styles.clickable]: !!onClick,
      })}
      onClick={handleOnClick}
    >
      <div
        className={styles.imageContainer}
        style={{ backgroundImage: `url("${placeholderUrl}")` }}
      >
        {creator && (
          <img
            src={ImageCreator}
            alt={intl.formatMessage(
              {
                id: "components.avatar.creatoralt",
                defaultMessage: "{name} is a creator on Peanut",
                description: "Alt text for creator avatars",
              },
              { name: displayName }
            )}
            className={styles.badge}
          />
        )}
        <picture>
          <source srcSet={avatarUrlWebp} type="image/webp" />
          <source srcSet={avatarUrl} type={`image/${ext}`} />
          <img
            src={avatarUrl}
            alt={displayName}
            className={styles.image}
            loading="lazy"
            data-testid="avatarImage"
          />
        </picture>
      </div>
    </div>
  );
};

Avatar.Mock = ({ size = "large" }: { size: AvatarSize }) => (
  <div className={classNames(styles.wrapper, styles[size])}>
    <div className={styles.imageContainer}>
      <Skeleton count={1} circle className={styles.image} />
    </div>
  </div>
);

export default Avatar;
